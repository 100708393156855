import React, { useEffect, useState } from "react";
import { WindowCleaningIcon } from "../../Icon/WindowCleaningIcon";
import { getWindows } from "../../helpers/Api";
import window from '../../../assets/images/window.svg';
import { CardServices } from "../../../shared/card-services/CardServices";

export function WindowCleaning({ windows, setWindows, priceOkno, setPriceOkno }) {

    const [filledInputs, setFilledInputs] = useState({});

    useEffect(() => {
        getWindows().then((data) => {
            setWindows(data);
        });
    }, [setWindows]);

    const handleChangeWindow = (event, optionId) => {
        const value = event.target.value;
        setPriceOkno((prevValues) => ({ ...prevValues, [optionId]: value }));

        setFilledInputs((prev) => ({
            ...prev,
            [optionId]: value.trim() !== '', // Если значение не пустое, устанавливаем true
        }));
    };

    const isInputEmpty = Object.values(priceOkno).every(value => !value);

    useEffect(() => {
        const items = document.querySelectorAll('#window-item');
        let maxHeight = 0;

        // Вычислить максимальную высоту
        items.forEach((item) => {
            const height = item.offsetHeight;
            if (height > maxHeight) {
                maxHeight = height;
            }
        });

        // Применить максимальную высоту
        items.forEach((item) => {
            item.style.height = `${maxHeight}px`;
        });
    }, [windows]);

    return (
        <div className="__osobiste__block __block__okna">
            <CardServices
                isInputEmpty={isInputEmpty}
                title='mycie okien'
            >
                <WindowCleaningIcon />
            </CardServices>
            <div className="__osobiste__block__right">
                <div className="mycie_okien">
                    {windows.map((option) => (
                        <div key={option.id} id="window-item" className="mycie_okien_item_wrapp">
                            <div className={`mycie_okien_item ${filledInputs[option.id] ? 'filled' : ''}`}>
                                <div className="image-wrapp">
                                    {option.image !== '/image/no-image' ? (
                                        <img src={option.image} alt={option.name} />
                                    ) : (
                                        <img src={window} alt="" />
                                    )}
                                </div>
                                <h6>{option.name}</h6>
                                <div className="mycie_okien_item__after">
                                    <input type="number" min="1" max="999"
                                        name={option.id}
                                        onChange={(event) => handleChangeWindow(event, option.id)}
                                        value={priceOkno[option.id] || ''}
                                        pattern="[1-9][0-9]*"
                                        onKeyPress={(event) => {
                                            // Блокируем ввод нуля в начале
                                            if (event.key === '0' && event.target.value.length === 0) {
                                              event.preventDefault();
                                            }
                                            // Блокируем ввод любых символов, кроме цифр
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}