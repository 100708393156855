import React, { useEffect, useState } from "react";
import { FurnitureCleaningIcon } from "../../Icon/FurnitureCleaningIcon";
import { getFurniture } from "../../helpers/Api";
import dust from '../../../assets/images/dust.svg';
import { CardServices } from "../../../shared/card-services/CardServices";

export function FurnitureCleaning({ furniture, setFurniture, priceFurniture, setPriceFurniture }) {
    const [filledInputs, setFilledInputs] = useState({});

    useEffect(() => {
        getFurniture().then((data) => {
            setFurniture(data);
        });
    }, [setFurniture]);

    const handleChangeFurniture = (event, optionId) => {
        const value = event.target.value;
        setPriceFurniture((prevValues) => ({ ...prevValues, [optionId]: value }));

        setFilledInputs((prev) => ({
            ...prev,
            [optionId]: value.trim() !== '', // Если значение не пустое, устанавливаем true
        }));
    };

    const isInputEmpty = Object.values(priceFurniture).every(value => !value);

    useEffect(() => {
        const items = document.querySelectorAll('#furniture-item');
        let maxHeight = 0;

        // Вычислить максимальную высоту
        items.forEach((item) => {
            const height = item.offsetHeight;
            if (height > maxHeight) {
                maxHeight = height;
            }
        });

        // Применить максимальную высоту
        items.forEach((item) => {
            item.style.height = `${maxHeight}px`;
        });
    }, [furniture]);

    return (
        <div className="__osobiste__block __block__mebel">
            <CardServices
                isInputEmpty={isInputEmpty}
                title='czyszczenie mebli'
            >
                <FurnitureCleaningIcon />
            </CardServices>
            <div className="__osobiste__block__right">
                <div className="mycie_okien">
                    {furniture.map((option) => (
                        <div key={option.id} id="furniture-item" className="mycie_okien_item_wrapp">
                            <div className={`mycie_okien_item ${filledInputs[option.id] ? 'filled' : ''}`}>
                                <div className="image-wrapp">
                                    {option.image !== '/image/no-image' ? (
                                        <img src={option.image} alt={option.name} />
                                    ) : (
                                        <img src={dust} alt="" />
                                    )}
                                </div>
                                <h6>{option.name}</h6>
                                <div
                                    className={`mycie_okien_item__after ${option.name === 'Wykładzina dywanowa' ? 'after-m2' : ''}`}
                                >
                                    <input type="number" min="1" max="999"
                                        name={option.id}
                                        onChange={(event) => handleChangeFurniture(event, option.id)}
                                        value={priceFurniture[option.id] || ''}
                                        pattern="[1-9][0-9]*"
                                        onKeyPress={(event) => {
                                            if (event.key === '0' && event.target.value.length === 0) {
                                                event.preventDefault();
                                            }

                                             // Блокируем ввод любых символов, кроме цифр
                                             if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                              }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}