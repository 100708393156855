import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Opinion.scss';
import ekaterina from '../../assets/images/ekaterina.png';
import ksyu from '../../assets/images/Ksyuhus.png';
import artyom from '../../assets/images/Artyom.png';
import anna from '../../assets/images/anna.png';
import anastasia from '../../assets/images/anastasia.png';
import jarek from '../../assets/images/Jarek.png';
import katarzyna from '../../assets/images/katarzyna.png';
import jach from '../../assets/images/jach.png';
// import { getComment } from '../helpers/Api';

export const Opinion = () => {
    
    const SamplePrevArrow = (props) => {
        const { onClick } = props;
        return <button className='prev-btn' onClick={onClick}>
            <svg width="30" height="70" viewBox="0 0 30 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27 1.56396L4 35.064L27 68.564" stroke="#4280BB" strokeWidth="5" />
            </svg>

        </button>;
    };

    const SampleNextArrow = (props) => {
        const { onClick } = props;
        return <button className='next-btn' onClick={onClick}>
            <svg width="30" height="70" viewBox="0 0 30 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 68.064L26 35.064L3 2.06397" stroke="#4280BB" strokeWidth="5" />
            </svg>

        </button>;
    };

    const settings = {
        arrows: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 840,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className='opinion'>
            <div className='container'>
                <div className='opinion__wrapp'>
                    <div className="opinion__title">
                        <div className="opinion__title-shadow">Opinie</div>
                        <div className="opinion__title-text">opinie</div>
                    </div>
                    <div className='slider'>
                        <Slider {...settings}>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    A
                                </span>
                                <span className='slider__name'>Antanina A</span>
                                <p>Вельмі прыемны Беларускі пан: нешта наблытаў i прыехаў на дзве гадзіны пазней.  Але, усю працу зрабіў вельмі добра, а потым не ўзяў з нас грошай i вельмі прасіў прабачэння.
                                    <br />Я вельмі задаволена вынікам чысткі мэблі, i крыху ў шоку ад лаяльнасці да заказчыкаў&#41;</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    <img src={ekaterina} alt="E" />
                                </span>
                                <span className='slider__name'>Ekaterina Romanouskaja</span>
                                <p>Сначала удивилась таким хорошим отзывом! После чистки дивана поняла - секрет в сервисе, который действительно на высоте! Спасибо - буду рекомендовать!</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    <img src={ksyu} alt="K" />
                                </span>
                                <span className='slider__name'>Ksyuhus</span>
                                <p>Очень рекомендую и очень понравилось! Очень была тщательная уборка, я за это время отдохнула и сделала важную работу, не отвлекаясь на бардак в доме.</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    M
                                </span>
                                <span className='slider__name'>Marina Borovskaya</span>
                                <p>Нашла компанию в инстаграм, воспользовалась услугой химчистки дивана. Казалось, что его невозможно отмыть&#41; так затерли светлый диван дети. Но через 2 часа он блестел как новенький. Рекомендую компанию👍</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    <img src={anna} alt="A" />
                                </span>
                                <span className='slider__name'>Anna Richter</span>
                                <p>Thank you very much for cleaning! it's really high quality! We will be in touch on a regular basis! and of course recommend to friends!</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    F
                                </span>
                                <span className='slider__name'>Francois Denne</span>
                                <p>We've been using this company a few times and they always deliver the best service! Today they even went over expectations, it was almost too clean, ha ha!<br />

                                    We highly recommend this company!</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    <img src={anastasia} alt="A" />
                                </span>
                                <span className='slider__name'>Анастасия Попова</span>
                                <p>dziękujemy za usługi świadczone przez pralnię chemiczną mebli tapicerowanych.<br />
                                    podczas konsultacji odpowiedzieli na wszystkie moje pytania i bez wątpienia zamówiłem usługę<br />
                                    pracownicy przybyli dokładnie o wyznaczonej godzinie<br />
                                    praca została wykonana szybko i sprawnie<br />
                                    zamówiono dodatkowe suszenie mebli, po zakończeniu prac pozostawiono do wyschnięcia mebla<br />
                                    Z całego serca polecam tę firmę sprzątającą Biało klining</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    O
                                </span>
                                <span className='slider__name'>Olga Makarova</span>
                                <p>Спасибо за отличный сервис. Было сложное пятно на матрасе. Все получилось, пятно перестало быть заметным. Также чистили диван, стал как новый. Понравилось, что мастер использовал бахилы и ароматные чистящие средства.</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    h
                                </span>
                                <span className='slider__name'>hammerpaul</span>
                                <p>Jestem bardzo zadowolony z usługi!&#41; Naprawdę polecam!</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    T
                                </span>
                                <span className='slider__name'>Таня Писоренко</span>
                                <p>Czyszczenie tapicerki na wyższym poziomie - profesjonalne podejście, szybkość i jakość! Łatwo można się skontaktować i umówić wizytę przez menedżera. Polecam z całego serca.</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    <img src={katarzyna} alt="K" />
                                </span>
                                <span className='slider__name'>Katarzyna Gruntkowska</span>
                                <p>Bardzo miła, uśmiechnięta ekipa. Sprzątanie bardzo dokładne, na pewno zamówimy ponownie :&#41;</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    <img src={artyom} alt="A" />
                                </span>
                                <span className='slider__name'>Artyom Vorobyov</span>
                                <p>Я заказывал чистку дивана и диванных подушек. Заказ делал через инстаграм. Общение было быстрым и приятным. Сегодня написал -- завтра специалист приехал, ровно во время, когда договаривались. Чистка была выполнена очень тщательно. До этого из дивана летело очень много пыли, после чистки пыли нет и диван имеет легкий приятный аромат&#41;&#41;</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    <img src={jach} alt="A" />
                                </span>
                                <span className='slider__name'>Anna Jach</span>
                                <p>Firma wykonała sprzątanie po remoncie w domu jednorodzinnym, jesteśmy zadowoleni i polecamy :&#41;</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    J
                                </span>
                                <span className='slider__name'>Joanna Pieczyńska</span>
                                <p>Punktualnie, sprawnie i co najważniejsze kanapa wyczyszczona bardzo dobrze 👍 <br />
                                    Zdecydowanie polecam!</p>
                            </div>
                            <div className='slider__item'>
                                <span className='slider__img'>
                                    <img src={jarek} alt="J" />
                                </span>
                                <span className='slider__name'>Jarek Kukuła</span>
                                <p>Bardzo dobra współpraca i jakość usług, przystępne ceny. Faktura za usługi i za to dodatkowy plus. Jestem bardzo zadowolony.</p>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}