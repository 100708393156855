import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useFetch } from '../../hooks/useFetchClient';

// Регулярное выражение для валидации формата телефона +48 xxx xxx xxx
const phoneRegExp = /^\+48 \d{3} \d{3} \d{3}$/;

export const useContactForm = (dodatkowe, windows, furniture, promocodeId, premisesCleaning, saleAll) => {
    const [inputPhone, setInputPhone] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [selectedMiasto, setSelectedMiasto] = useState({ id: 1, name: 'Wroclaw' });
    const [priceFurniture, setPriceFurniture] = useState({});
    const [priceOkno, setPriceOkno] = useState({});
    const filteredCheckboxes = {};
    const [checkboxes, setCheckboxes] = useState({});
    const [discount, setDiscount] = useState(0);
    const [discountPhone, setDiscountPhone] = useState(0);
    const [changeUslugiFirstBlock, setChangeUslugiFirstBlock] = useState(0);
    const [priceOne, setPriceOne] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const date = new Date(selectedDate); // текущая дата
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // добавляем ведущий ноль, если месяц < 10
    const day = String(date.getDate()).padStart(2, '0'); // добавляем ведущий ноль, если день < 10
    const formattedDate = `${year}-${month}-${day}`;

    const summDodat = () => {
        let summ = 0;
        if (priceOne !== '') {
            dodatkowe.forEach(option => {
                if (checkboxes[option.id]) {
                    summ += Number(option.price);
                }
            });
        }
        return summ;
    };

    const summSecond = () => {
        let summ = 0;
        windows.forEach((option) => {
            const value = priceOkno[option.id] || 0;
            summ += value * option.price;
        });
        return summ;
    };

    const summThird = () => {
        let summ = 0;
        furniture.forEach((option) => {
            const value = priceFurniture[option.id] || 0;
            summ += value * option.price;
        });
        return summ;
    }

    const changeServices = () => {
        // Ищем объект с соответствующим id в premisesCleaning
        const selectedService = premisesCleaning.find((service) => service.id === 2); // Для return 9 (id: 2, price: 60)
        if (!changeUslugiFirstBlock) {
            // Проверяем, существует ли объект, прежде чем обращаться к свойству price
            if (selectedService) {
                return Number(selectedService.price); // Преобразуем price в число и возвращаем его
            } else {
                // Если объект не найден, возвращаем значение по умолчанию
                return 0; // Или любое другое значение по умолчанию, которое вам нужно
            }
        } else {
            // Ищем объект с соответствующим id для return 12 (id: 3, price: 70)
            const selectedService = premisesCleaning.find((service) => service.id === 3);
            if (selectedService) {
                return Number(selectedService.price);
            } else {
                return 0; // Здесь также можно вернуть значение по умолчанию
            }
        }
    };

    const summFirst = () => {
        let summ = 0;
        // Ищем объект с соответствующим id для let sale = 0.02 (id: 1, price: 2)
        const selectedSale = premisesCleaning.find((sale) => sale.id === 1);
        let price = changeServices();

        if (priceOne <= 20) {
            summ = priceOne * price;
        } else {
            if (priceOne > 200) {
                summ = priceOne * 5;
            } else {
                // Проверяем, существует ли объект selectedSale, прежде чем использовать его свойство price
                if (selectedSale) {
                    summ = priceOne * (price - priceOne * (Number(selectedSale.price)));
                } else {
                    summ = priceOne * price; // Или используем значение price по умолчанию
                }
            }
        }

        return summ;
    };


    const baseTotal = summFirst() + summDodat() + summSecond() + summThird();
    const formattedDateSale = selectedDate?.toLocaleDateString('en-CA'); // Формат "YYYY-MM-DD"
    const sale = saleAll.find((d) => d.date === formattedDateSale);

    const getDailyDiscount = () => {
        if (!selectedDate) return 0;

        if (sale) {
            return sale.type === 0 ? -sale.sale : sale.sale; // Если type === 0, это скидка, иначе надбавка
        }

        return 0;
    };

    const dailyDiscount = getDailyDiscount(); // Получаем скидку дня

    const priceSumm = () => {
        let totalDiscountPercent = 0;

        // Суммируем все проценты скидок
        if (discount > 0) totalDiscountPercent += discount;
        if (discountPhone > 0) totalDiscountPercent += discountPhone;

        if (dailyDiscount < 0) {
            totalDiscountPercent += Math.abs(dailyDiscount); // Если это скидка (отрицательное значение), добавляем её к общей скидке
        } else if (dailyDiscount > 0) {
            totalDiscountPercent -= dailyDiscount; // Если это надбавка (положительное значение), уменьшаем общий процент скидки
        }

        // Ограничиваем итоговую скидку максимумом 100% (чтобы избежать отрицательной суммы)
        totalDiscountPercent = Math.min(totalDiscountPercent, 100);

        // Рассчитываем итоговую сумму
        const finalAmount = baseTotal - baseTotal * (totalDiscountPercent / 100);

        return Math.round(finalAmount);
    };

    // const mamyChasy = () => {
    //     if (priceSumm() <= 1000) return Math.round(priceSumm() / 120);
    //     if (priceSumm() <= 2000) return Math.round(priceSumm() / 180);
    //     if (priceSumm() > 1000) return Math.round(priceSumm() / 240);
    // }
    //в подсчёте time_for_job используем baseTotal, так как это чистая цена без учёта скидок
    const mamyChasy = () => {
        if (baseTotal <= 1000) return Math.round(baseTotal / 120);
        if (baseTotal <= 2000) return Math.round(baseTotal / 180);
        if (baseTotal > 1000) return Math.round(baseTotal / 240);
    }


    const {
        loading,
        statusForm,
        setStatusForm,
        errorMesForm,
        isOpenModalSuccess,
        isOpenModalError,
        isOpenModalErrorDate,
        fetchClient,
        setIsOpenModalSuccess,
        setIsOpenModalError,
        setIsOpenModalErrorDate
    } = useFetch();

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            adress: '',
            kod_index: '',
            notes: '',
            vat: '',
        },
        validationSchema: yup.object({
            firstname: yup.string()
                .required('Wypełnij pole'),
            lastname: yup.string(),
            email:
                yup.string().email('Adres e-mail jest nieprawidłowy')
                    .required('Wypełnij pole'),
            phone: yup.string()
                .matches(phoneRegExp, 'Numer telefonu jest nieprawidłowy')
                .required('Wypełnij pole'),
            adress: yup.string()
                .required('Wypełnij pole'),
            kod_index: yup.string(),
            notes: yup.string(),

            vat: yup.string()
                .matches(/^\d+$/, 'Numer NIP musi składać się tylko z cyfr')
                .length(10, 'Numer NIP musi mieć dokładnie 10 cyfr'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const formData = new FormData();
            formData.append('firstname', values.firstname);
            formData.append('lastname', values.lastname);
            formData.append('email', values.email);
            formData.append('phone', values.phone);
            formData.append('adress', values.adress);
            formData.append('notes', values.notes);
            formData.append('kod_index', values.kod_index);
            if (values.vat !== '') {
                formData.append('vat', values.vat)
            }

            formData.append('locale_id', selectedMiasto.id)
            if (priceOne !== '') {
                formData.append('sprzatanie_id', JSON.stringify({ [changeUslugiFirstBlock === 1 ? 3 : 2]: priceOne }))
                formData.append('dodatkowe_id', JSON.stringify(filteredCheckboxes))
            }

            formData.append('mebel_id', JSON.stringify(priceFurniture))
            formData.append('okien_id', JSON.stringify(priceOkno))

            formData.append('order_date', formattedDate)
            formData.append('order_time', new Date(selectedDate).toLocaleTimeString())
            formData.append('time_for_job', mamyChasy());
            formData.append('total', Math.round(priceSumm()));
            formData.append('promocode_id', promocodeId);
            formData.append('promo_discount', discount);
            formData.append('personal_discount', discountPhone === null ? 0 : discountPhone);

            if (sale !== null && sale !== undefined) {
                formData.append('sale_type', sale.type);
                formData.append('sale', sale.sale);
            }

            const formReset = () => {
                setIsFocused(false);
                setInputPhone('');
                resetForm();
                setSelectedMiasto({ id: 1, name: 'Wroclaw' });
                setPriceFurniture({});
                setPriceOkno({});
                setCheckboxes((prevState) => {
                    const clearedCheckboxes = { ...prevState };
                    for (const key in clearedCheckboxes) {
                        clearedCheckboxes[key] = false;
                    }
                    return clearedCheckboxes;
                });
                setSelectedDate(null);
                setChangeUslugiFirstBlock(0);
                setPriceOne('');
                setDiscount(0);
                setDiscountPhone(0);
            }

            await fetchClient('/api/create-order', 'POST', formData, formReset);
        },
    });

    return {
        formik,
        inputPhone,
        setInputPhone,
        isFocused,
        setIsFocused,
        loading,
        statusForm,
        setStatusForm,
        errorMesForm,
        isOpenModalSuccess,
        isOpenModalError,
        isOpenModalErrorDate,
        setIsOpenModalSuccess,
        setIsOpenModalError,
        setIsOpenModalErrorDate,
        selectedMiasto,
        setSelectedMiasto,
        priceFurniture,
        setPriceFurniture,
        priceOkno,
        setPriceOkno,
        filteredCheckboxes,
        checkboxes,
        setCheckboxes,
        selectedDate,
        setSelectedDate,
        discount,
        setDiscount,
        discountPhone,
        setDiscountPhone,
        priceSumm,
        baseTotal,
        changeUslugiFirstBlock,
        setChangeUslugiFirstBlock,
        priceOne,
        setPriceOne,
        mamyChasy,
        summFirst,
        summSecond,
        summThird,
        summDodat,
        dailyDiscount,
    };
};