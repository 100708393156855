import React from 'react';
// import { lazy, Suspense } from "react";
import Layout from './components/Layout/Layout';
import { Route, Routes } from 'react-router-dom';
import HomePages from './pages/HomePages';
import ForCompanies from './pages/ForCompanies';
import './App.styles.scss';

// const LazyHomePage = lazy(() => import('./pages/HomePages'));
// const LazyForCompanies = lazy(() => import('./pages/ForCompanies'));
// const LazyLayout = lazy(() => import('./components/Layout/Layout'));

export function App() {
  return (
    <div className="App">
      {/* <Routes>
          <Route path='/' element={<LazyLayout />}>
            <Route index element={<LazyHomePage />} />
            <Route path='/for-companies' element={<LazyForCompanies />} />
          </Route>
        </Routes> */}
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePages />} />
          <Route path='/for-companies' element={<ForCompanies />} />
        </Route>
      </Routes>
    </div>

  );
}
