import React, { useState } from "react";
import DatePicker from 'react-datepicker';
import pl from "date-fns/locale/pl";
import { useEffect } from "react";
import { getOrderDate, getSalesAll, postOrderDate } from "../../helpers/Api";
import 'react-datepicker/dist/react-datepicker.css';
import '../Datepicker/Datepicker.scss';
import { LoaderCalendar } from "../../../shared/loader/loaderСalendar";

export function Datepicker({ onDateChange, selectedDate, setSelectedDate, saleAll, setSaleAll, formattedDate, handlePostOrderDate, reservedTimes, loadingDate, setLoadingDate }) {

  const today = new Date();
  const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2); // заказать услугу можно не ранее, чем через три дня
  const maxDate = new Date(today.getFullYear(), today.getMonth() + 3, today.getDate()); // услугу можно заказать на три месяца вперёд
  const excludedHours = [0, 1, 2, 3, 4, 5, 6, 7, 9, 11, 13, 15, 17, 18, 19, 20, 21, 22, 23];
  const excludeTimes = excludedHours.map(hour => new Date(0, 0, 0, hour, 0));

  // const [reservedTimes, setReservedTimes] = useState([]);

  // const [loading, setLoading] = useState(false)

  // const formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-CA') : null;

  // const handlePostOrderDate = () => {
  //   setLoading(true);
  //   postOrderDate({
  //     day: formattedDate
  //   }).then((res) => {
  //     // Обрабатываем ответ
  //     const disabledTimes = res.data
  //       .filter(item => item.status === 0) // Фильтруем только те, у которых статус 0
  //       .map(item => ({
  //         date: item.date, // Дата
  //         time: item.date_time, // Время 
  //         disabled: true // Помечаем как disabled
  //       }));

  //     // Устанавливаем disabledTimes в состояние
  //     // setReservedTimes(prev => [...prev, ...disabledTimes]);
  //     setReservedTimes(disabledTimes);
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 300);

  //   })
  //     .catch(e => {
  //       if (e.response.status !== 200) {
  //         setTimeout(() => {
  //           setLoading(false);
  //         }, 1000);
  //         return
  //       }
  //     })
  // };

  // handlePostOrderDate()

    // Используем useEffect для отслеживания изменений selectedDate
    useEffect(() => {
      if (formattedDate) {
        handlePostOrderDate(); // Вызываем запрос к серверу при изменении даты
      }
    }, [formattedDate]); // Зависимость от formattedDate


  // const excludedTimes = reservedTimes.reduce((acc, times) => {
  //   times.forEach((time) => {
  //     const date1 = new Date(selectedDate).toLocaleString().slice(0, 10)
  //     const date2 = new Date(time.date).toLocaleString().slice(0, 10)
  //     if (selectedDate && date1 === date2 && time.disabled) {
  //       const hour = parseInt(time.time.split(":")[0]);
  //       acc.push(new Date(0, 0, 0, hour, 0));
  //     }
  //   });
  //   return acc;
  // }, [...excludeTimes]);

  useEffect(() => {
    getSalesAll().then((res) => {
      setSaleAll(res.data);
    })
  }, []);


  const excludedTimes = reservedTimes.reduce((acc, time) => {
    const date1 = new Date(selectedDate).toLocaleString().slice(0, 10);
    const date2 = new Date(time.date).toLocaleString().slice(0, 10);
    if (selectedDate && date1 === date2 && time.disabled) {
      const hour = parseInt(time.time.split(":")[0]);
      acc.push(new Date(0, 0, 0, hour, 0));
    }
    return acc;
  }, [...excludeTimes]);

  const renderDayContents = (day, date) => {
    const formattedDate = date.toLocaleDateString('en-CA'); // Format the date as "YYYY-MM-DD"
    const sale = saleAll.find((d) => d.date === formattedDate);

    // Проверяем, доступна ли дата в пределах minDate и maxDate
    const isDateAvailable = date >= minDate && date <= maxDate;

    if (isDateAvailable && sale) {
      const symbol = sale.type === 0 ? "-" : "+"; // Определяем символ на основе типа
      return (
        <div className="day-wrapp">
          <div className={`sale ${sale.type === 0 ? "minus" : "plus"}`}>
            {symbol}{sale.sale}%
          </div>
          <span>{day}</span>
        </div>
      );
    }

    return <span>{day}</span>;
  };



  const handleDateTimeChange = (date) => {
    if (!selectedDate) {
      // Если даты нет, просто устанавливаем её с нулевым временем
      const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setSelectedDate(newDate); // Сбрасываем выбранное время
      onDateChange(newDate);
    } else {

      // Если дата уже выбрана, определяем, изменилось ли время или дата
      const isDateChanged =
        selectedDate.getFullYear() !== date.getFullYear() ||
        selectedDate.getMonth() !== date.getMonth() ||
        selectedDate.getDate() !== date.getDate();

      if (isDateChanged) {
        // Дата изменилась: сбрасываем время
        const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        setSelectedDate(newDate);
        onDateChange(newDate);
        // handlePostOrderDate();
      } else {
        // Время изменилось: обновляем дату с новым временем
        const updatedDate = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          date.getHours(),
          date.getMinutes()
        );
        setSelectedDate(updatedDate);
      }
    }
  };


  const timeClassName = (time) => {
    const isBookedTime = excludedTimes.some(excludedTime =>
      time.getHours() === excludedTime.getHours() &&
      time.getMinutes() === excludedTime.getMinutes()
    );
    if (!selectedDate) {
      return "all-disabled-time";
    }
    return isBookedTime ? 'disabled-time' : 'undisabled-time';

  }

  return (
    <div className="block-form__form__left__termin">
      <div className="block-form__form__left__osobiste">
        <h5>Wybierz termin <span>(wymagane)</span></h5>

        <div className="data-czas">
          {loadingDate && <LoaderCalendar loading={loadingDate} />}
          <DatePicker
            selected={selectedDate}
            onChange={handleDateTimeChange}
            dateFormat="dd.MM.yyyy"
            minDate={minDate}
            locale={pl}
            timeCaption="godzina"
            showTimeSelect
            inline
            shouldCloseOnSelect={false}
            timeIntervals={60}
            excludeTimes={excludeTimes}
            timeClassName={timeClassName}
            disabled={!selectedDate}
            maxDate={maxDate}
            renderDayContents={renderDayContents}
          />
        </div>
      </div>
    </div>

  );
};