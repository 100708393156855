import { useEffect } from "react";
import { BeatLoader, DotLoader, FadeLoader } from "react-spinners";

const override = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  display: "block",
  zIndex: 100,
};


export function Loader({ loading }) {

  useEffect(() => {
    if (loading) {
      document.documentElement.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.documentElement.style.overflow = 'auto'; // Enable scrolling
    }

    // Clean up function to reset the overflow style
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, [loading]);

  return (
    <div
      className="loader__wrapp"
      style={{
        // Стили для подложки, делающей фон прозрачным
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        display: loading ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <div
        style={{
          pointerEvents: 'none', // Предотвращает взаимодействие с элементами
          opacity: 1, // Прозрачность фона
        }}
      >
        <BeatLoader
          color={'#4280BB'}
          loading={loading}
          cssOverride={override}
          // radius={20}
          size={22}
          // speedMultiplier={1}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  )
}